/* You can add global styles to this file, and also import other style files */
@import "colours";
@import "values";
@import "bulma-slider";
@import "bulma";

@import "highlight.js/scss/monokai-sublime"; // Included for syntax highlighting in Visicode API documentation


body, html {
	background-color: $backgroundColor;
	color: var(--textColor, $textColor);
	height: 100%;
}

[filedrop] {
	border: 4px dotted transparent;

	&.file-drag-over {
		border-color: $primary;
		border-color: var(--primary, $primary);
	}
}

.button {
	&:focus, &.is-focused {
		border-color: $midGrey;

		&:not(:active) {
			box-shadow: none;
		}
	}
}

fieldset {
	margin-bottom: $fieldset-spacing;

	legend {
		color: $red;
	}
}

.episode {
	.field-label {
		flex-grow: 3;
	}

	.field-body {
		flex-grow: 7;
	}
}


h2.subtitle {
	// color: $red;
	font-weight: bold;
}

breadcrumbs {
	display: block;
	margin-bottom: 1rem;
}

div.tags {
	.tag {
		cursor: default;
		user-select: none;
	}
}

.button {
	&> fa-icon:not(:last-child) {
		margin-right: 0.5rem;
	}
}

table.is-small {
	font-size: 0.9em;
}

.select select::placeholder,
.textarea::placeholder,
.input::placeholder {
	color: $semiGrey;
}

.buttons {
	button {
		margin-bottom: 0;
	}

	.dropdown-menu {
		padding-top: 0;
	}
}

form-field {
	display: block;
	margin-bottom: 0.75rem;
}

li.cdk-drag, li.cdk-drag-dragging, li.cdk-drag-preview {
	list-style-type: none !important;
}

textarea {
	resize: vertical;
}

@import "colours";
$fieldset-spacing: 1rem;

$navbar-background-color: $headerBackground;
$navbar-item-hover-background-color: $headerBackground;
$navbar-dropdown-background-color: $headerBackground;
$navbar-item-hover-color: $red;
$navbar-dropdown-item-hover-color: $red;


:root {
	--modalScrollOffset: 0;
}

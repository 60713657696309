$orange: #ff4900;
$grey: #797979;
$lightGrey: #e9e9e9;
$midGrey: #c7c7c7;
$semiGrey: #777;
$darkGrey: #444;
$red: #c70000;
$blue: #4466aa;

$titleColor: #363636;
$subtitleColor: $darkGrey;
$backgroundColor: #ffffff;
// $tabBorder: #dbdbdb;
$tabBorder: $red;
$boxBackground: #fff;
$textColor: $darkGrey;
$headerBackground: #414141;

$primary: $blue;
$secondary: $lightGrey;

:root {
	--backgroundColor: #{$backgroundColor};
	--textColor: #{$textColor};
	--boxColor: #{$textColor};
	--titleColor: #{$titleColor};
	--subtitleColor: #{$subtitleColor};
	--boxBackground: #{$boxBackground};
	--tabBorder: #{$tabBorder};

	--primary: #{$primary};
	--secondary: #{$secondary};
	--tabs-link-active-color: #{$primary};
	--tabs-link-disabled-color: #{$secondary};
}

$link: $primary;
$link: var(--primary, $primary);

$dangerRed: #f13658;

$overlayBackground: rgba(255, 255, 255, 0.8);
